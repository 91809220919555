import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Dashboard.scss'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

export default function Home() {
  return (
    <div className="
      w-full
      ">
      <div className="
        flex
        justify-end
        ">
        <div className="
          bg-blue-600
          rounded-full
          w-10
          h-10
          flex
          justify-center
          items-center
          text-2xl
          transition-all
          duration-200
          hover:bg-blue-700
          cursor-pointer
          text-white
          ">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      <div className="
        flex
        flex-col
        gap-5
        mt-6
        w-full
        ">
          {/* Post styling start */}
          <div className="
            flex
            py-5
            px-6
            justify-between
            bg-secoundary
            rounded-lg
            items-center
            ">
            <div className="
              flex
              flex-col
              gap-5
              max-w-screen-sm
              ">
              <div className="
                text-xl
                ">
                Tidak bisa kerja dan tidak bisa belajar aaaaaaaaaaaaaa aaaaaaaaaaa aaaa aaaa aaaaa aaaaa vvv vvvv fffff gggggg
              </div>
              <div className="text-secfont">
                10 October 2024
              </div>
            </div>
            <div className="
              flex
              gap-10
              ">
              <div className="
                text-blue-600
                hover:text-blue-700
                transition-all
                duration-200
                cursor-pointer
                text-2xl
                ">
                <FontAwesomeIcon icon={faPen} />
              </div>

              <div className="
                text-red-600
                hover:text-red-700
                transition-all
                duration-200
                cursor-pointer
                text-2xl
                ">
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
          {/* Post styling end */}
          {/* Post styling start */}
          <div className="
            flex
            py-5
            px-6
            justify-between
            bg-secoundary
            rounded-lg
            items-center
            ">
            <div className="
              flex
              flex-col
              gap-5
              max-w-screen-sm
              ">
              <div className="
                text-xl
                ">
                Tidak bisa kerja dan tidak bisa belajar aaaaaaaaaaaaaa aaaaaaaaaaa aaaa aaaa aaaaa aaaaa vvv vvvv fffff gggggg
              </div>
              <div className="text-secfont">
                10 October 2024
              </div>
            </div>
            <div className="
              flex
              gap-10
              ">
              <div className="
                text-blue-600
                hover:text-blue-700
                transition-all
                duration-200
                cursor-pointer
                text-2xl
                ">
                <FontAwesomeIcon icon={faPen} />
              </div>

              <div className="
                text-red-600
                hover:text-red-700
                transition-all
                duration-200
                cursor-pointer
                text-2xl
                ">
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
          {/* Post styling end */}
      </div>
    </div>
  )
}
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import './AuthPage.scss'

export default function Auth() {
  return (
    <div className='flex flex-col p-5 rounded-lg w-1/4 gap-5 bg-secoundary'>
      <div className="flex justify-center">
        <h1 className='text-2xl font-medium'>Login</h1>
      </div>
      {/* username form */}
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <FontAwesomeIcon icon={faUserCircle} />
        </div>
        <input type="text" id="input-group-1" className="
          bg-primary
          text-fontprim
          text-sm
          rounded-lg
          block
          w-full
          ps-10
          p-2.5
        "
          placeholder="email@website.com"/>
      </div>
      {/* Password form */}
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <FontAwesomeIcon icon={faLock} />
        </div>
        <input type="password" id="input-group-1" className="
          bg-primary
          text-fontprim
          text-sm
          rounded-lg
          block
          w-full
          ps-10
          p-2.5
        " placeholder="Password" />
      </div>
      {/* Button */}
      <Link to="/" className="
        text-center
        bg-blue-600
        hover:bg-blue-700
        text-white
        font-medium
        py-2 px-4
        rounded-lg
        mb-5
        transition-all
      ">
        Login
      </Link>
    </div >
  )
}
export default function DefaultLayout( { children }: { children: React.ReactNode } ) {
  return (
    <div className='flex flex-col mt-10'>
      <div className="text-5xl font-extralight text-center">The note of ERDP</div>
      <div className="flex justify-center">
        {children}
      </div>
    </div>
  )
}

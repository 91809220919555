import './Home.scss'

export default function Home() {
  return (
    <div className='container'>
      <div className="
        flex
        justify-center
        ">
        <div className="
          p-5
          bg-secoundary
          rounded-lg
          portal
          transition
          ease-in-out
          hover:-translate-y-1
          hover:-translate-x-1
          hover:drop-shadow-xl
          duration-300
          cursor-pointer
          ">
            <div className="
              title
              text-2xl
              ">
              Tidak bisa kerja dan tidak bisa belajar aaaaaaaaaaaaaa aaaaaaaaaaa aaaa aaaa aaaaa aaaaa vvv vvvv fffff gggggg
            </div>
            <div className="
              text-secfont
              mt-5
              text-right
              ">
              10 Oktober 2024
            </div>
        </div>
      </div>
    </div>
  )
}
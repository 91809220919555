import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.scss'
import Home from './pages/Home/Home'
import AuthPage from './pages/AuthPage/AuthPage'
import Dashboard from './pages/Dashboard/Dashboard'
import NotFound from './pages/NotFound/NotFound'
import DefaultLayout from './layouts/DefaultLayout'
import DashboardLayout from './layouts/DashboardLayout'
import FullPage from './layouts/FullPage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<DefaultLayout> <Home /> </DefaultLayout>} />
        <Route path='/dashboard' element={<DashboardLayout> <Dashboard /> </DashboardLayout>} />
        <Route path='/auth' element={<FullPage> <AuthPage /> </FullPage>} />
        <Route path='*' element={<FullPage> <NotFound /> </FullPage>} />
      </Routes>
    </Router>
  )
}

export default App

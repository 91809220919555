import { Link } from 'react-router-dom'
import './NotFound.scss'

export default function NotFound() {
  return (
    <div className='flex flex-col text-center'>
      <div className='uppercase'>oops! page not found</div>
      <div className='font-bold text-super flex leading-none'>404</div>
      <div className='pt-10'>
        <Link to="/" className='px-5 py-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-all'>Go Back Home</Link>
      </div>
    </div>
  )
}
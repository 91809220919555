import { faNewspaper, faNoteSticky, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export default function DashboardLayout( { children }: { children: React.ReactNode } ) {
  return (
    <div className="
      flex
      ">
      <div className="
        bg-secoundary
        left
        w-3/12
        h-screen
        sticky
        top-0
        ">
        <nav>
          <div className="
            flex
            justify-center
            text-3xl
            font-medium
            mt-5
            mb-10
            ">
              <span className="text-secfont">
                ERDP Note
              </span>
          </div>
          <ul className="
              flex
              flex-col
            ">
              {/* Start of navigaiton list */}
            <li className="
              px-4
              ">
              <Link to="#" className="
                flex
                w-full
                transition-all
                duration-300
                py-3
                px-5
                rounded-lg
                bg-secoundary
                hover:bg-primary
                ">
                  <div className="
                    bg-orange
                    ">
                      <span className="pr-5">
                        <FontAwesomeIcon icon={faNewspaper} />
                      </span>
                      <span>Post</span>
                  </div>
                </Link>
            </li>
            {/* Ed of navigation list */}
              {/* Start of navigaiton list */}
            <li className="
              px-4
              ">
              <Link to="#" className="
                flex
                w-full
                transition-all
                duration-300
                py-3
                px-5
                rounded-lg
                bg-secoundary
                hover:bg-primary
                ">
                  <div className="
                    bg-orange
                    ">
                      <span className="pr-5">
                        <FontAwesomeIcon icon={faNoteSticky} />
                      </span>
                      <span>Draft</span>
                  </div>
                </Link>
            </li>
            {/* Ed of navigation list */}
              {/* Start of navigaiton list */}
            <li className="
              px-4
              ">
              <Link to="#" className="
                flex
                w-full
                transition-all
                duration-300
                py-3
                px-5
                rounded-lg
                bg-secoundary
                hover:bg-primary
                ">
                  <div className="
                    bg-orange
                    ">
                      <span className="pr-5">
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                      <span>Deleted</span>
                  </div>
                </Link>
            </li>
            {/* Ed of navigation list */}
          </ul>
        </nav>
      </div>
      <div className="
        m-5
        w-full
      ">
        {children}
      </div>
    </div>
  )
}